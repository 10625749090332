* {
  margin: 0px;
  padding: 0px;
}

main {
  min-height: 60vh;
}

body {
  /* background-color: #EEEEEE; */
  font-family: "Times New Roman", Times, serif;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: var(--blueThemeColor);
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}

/* css for button use in all screens */
.btnAllScreen {
  /* margin: 49px 0px; */
  padding: 10px 30px;
  background: var(--blueThemeColor) !important;
  border: none;
  cursor: pointer;
}

.btnAllScreen:hover {
  background: var(--blueThemeColor) !important;
  opacity: 0.8;
}
.btnAllScreen:active {
  background: var(--blueThemeColor) !important;
  opacity: 0.8;
}

.btnAllScreen::after {
  background: var(--blueThemeColor) !important;
  opacity: 0.8;
}

/* css for button use in all screens */
.btnSeeMore {
  /* margin: 49px 0px; */
  padding: 5px 20px;
  background: var(--blueThemeColor) !important;
  border: none;
  margin-top: 40px;
  cursor: pointer;
}

.btnSeeMore:hover {
  background: var(--blueThemeColor) !important;
  opacity: 0.8;
}
.btnSeeMore:active {
  background: var(--blueThemeColor) !important;
  opacity: 0.8;
}

.btnSeeMore::after {
  background: var(--blueThemeColor) !important;
  opacity: 0.8;
}

td {
  vertical-align: middle;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: rgb(236, 229, 229);
}

.form-check-input:checked {
  background-color: var(--blueThemeColor);
  border-color: var(--blueThemeColor);
}
.img-crop-control {
  padding: 0px;
}

#shadow-host-companion {
  padding: 0px;
}

.ant-modal-wrap {
  z-index: 1050; /* Ensure cropping modal appears above other modals */
}

.ant-upload-list-item-container {
  border-radius: 1130px;
}

/* pagination */
.page-link,
.page-link {
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 3px;

  /* border-color: var(--bs-pagination-active-border-color); */
}

.active > .page-link,
.page-link.active {
  border-radius: 50%;
  background-color: var(--blueThemeColor);
  /* border-color: var(--bs-pagination-active-border-color); */
}

.deleteAndEditBtn {
  border-radius: 50%;
  background-color: var(--blueThemeColor);
  color: #fff;
}
